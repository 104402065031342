@if (tableData) {
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="fixed p"><div class="graph-indicator-spacing"></div></th>
          @for (column of tableData.fixedColumns; track column.name) {
            <th class="fixed p">
              <strong>{{ escapeNewLine(column.name) }}</strong>
            </th>
          }
          @for (column of tableData.columns; track column.name) {
            <th class="p">
              <strong>{{ escapeNewLine(column.name) }}</strong>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of tableData.rows; let rowIndex = $index; track rowIndex) {
          <tr>
            <td class="fixed p">
              <div class="stretch-container toolbar-item card-layout">
                <button
                  type="button"
                  [disabled]="isRowEmpty(row)"
                  [attr.aria-label]="'shared.laboratory-results.open-graph-view' | translate"
                  [attr.title]="'shared.laboratory-results.open-graph-view' | translate"
                  class="graph-indicator tertiary"
                  (click)="openGraphModal(tableData.columns, row.cells, row.fixedCells)">
                  <fa-icon icon="chart-line" size="sm"></fa-icon>
                </button>
              </div>
            </td>
            @for (cell of row.fixedCells; let columnIndex = $index; track columnIndex) {
              <td class="fixed p">{{ cell.value }}</td>
            }
            @for (cell of row.cells; let columnIndex = $index; track columnIndex) {
              <td class="p" [class.noValue]="!cell.value" [ngStyle]="getCellStyles(cell)">
                <div class="cell-container">
                  @if (cell.notes?.length) {
                    <div class="icon-section">
                      <div
                        class="info-icon-container"
                        [ngStyle]="getInfoIconContainerStyles(cell)"
                        cdkOverlayOrigin
                        #trigger="cdkOverlayOrigin"
                        (click)="showNotes(cell.notes)"
                        (mouseenter)="showInfoOverlay(rowIndex, columnIndex)"
                        (mouseleave)="dismissInfoOverlay($event, connectedOverlay)">
                        <fa-icon
                          icon="question"
                          size="2xs"
                          [ngStyle]="getInfoIconStyles(cell)"></fa-icon>
                      </div>

                      <ng-template
                        cdkConnectedOverlay
                        #connectedOverlay="cdkConnectedOverlay"
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="isOverlayOpen(rowIndex, columnIndex)"
                        (attach)="handleOverlayLayout(connectedOverlay)">
                        <div class="popover-wrapper" #overlayContainer>
                          <div
                            class="popover-container"
                            [innerHTML]="cell.notes[0] | safeHtml"></div>
                          @if (showMoreNoteDetails) {
                            <div class="note-details">
                              <div
                                class="info-icon-container"
                                [ngStyle]="getInfoIconContainerStyles(cell, true)">
                                <fa-icon icon="question" size="2xs"></fa-icon>
                              </div>
                              <span>{{
                                'shared.laboratory-results.more-note-details' | translate
                              }}</span>
                            </div>
                          }
                        </div>
                      </ng-template>
                    </div>
                  }
                  <div class="cell-data" [ngStyle]="getDataCellStyles(cell)">
                    @if (cell.flag?.icon) {
                      <div
                        class="indicator-container"
                        [ngStyle]="getIndicatorContainerStyles(cell)">
                        <fa-icon [icon]="cell.flag.icon" size="2xs"></fa-icon>
                      </div>
                    }
                    <span>{{ cell.value }}</span>
                  </div>
                </div>
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
}
