import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { APP_NAME } from '../injection-tokens';
import { AppName } from '../models/app.model';

export const objectNameMapping = {
  PP_ALL_PATIENTS: {
    'p-p': 'PP_ALL_PATIENTS',
    'z-p': 'ZP_ALL_PATIENTS',
    'k-p': 'ZP_ALL_PATIENTS',
    'm-p': 'PP_ALL_PATIENTS',
  },
  PP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID: {
    'p-p': 'PP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
    'z-p': 'ZP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
    'k-p': 'ZP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
    'm-p': 'PP_FIND_ALL_APPOINTMENTS_FROM_PAT_W_ID',
  },
  PP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID: {
    'p-p': 'PP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID',
    'z-p': 'ZP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID',
    'k-p': 'ZP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID',
    'm-p': 'PP_FIND_ALL_DOCUMENTS_FROM_PAT_W_ID',
  },
  PP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID: {
    'p-p': 'PP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID',
    'z-p': 'ZP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID',
    'k-p': 'ZP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID',
    'm-p': 'PP_FIND_ALL_TREATMENTS_FROM_PAT_W_ID',
  },
  PP_IMPRINT_PAGE: {
    'p-p': 'PP_IMPRINT_PAGE',
    'z-p': 'ZP_IMPRINT_PAGE',
    'k-p': 'ZP_IMPRINT_PAGE',
    'm-p': 'PP_IMPRINT_PAGE',
  },
  PP_PRIVACY_POLICY_PAGE: {
    'p-p': 'PP_PRIVACY_POLICY_PAGE',
    'z-p': 'ZP_PRIVACY_POLICY_PAGE',
    'k-p': 'ZP_PRIVACY_POLICY_PAGE',
    'm-p': 'PP_PRIVACY_POLICY_PAGE',
  },
  PP_FIND_PATIENT_BY_ID: {
    'p-p': 'PP_FIND_PATIENT_BY_ID',
    'z-p': 'ZP_FIND_PATIENT_BY_ID',
    'k-p': 'ZP_FIND_PATIENT_BY_ID',
    'm-p': 'PP_FIND_PATIENT_BY_ID',
  },
};
export const baseNames = new Set(Object.keys(objectNameMapping));

export const ReplaceObjectNamesForPortalsInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  if (!req.url.includes('/objects/')) {
    return next(req);
  }
  const objectName = req.url.split('/objects/').pop();
  if (!baseNames.has(objectName)) {
    return next(req);
  }
  const appName: AppName = inject(APP_NAME);

  const mappedObjectName = objectNameMapping[objectName]?.[appName];

  if (mappedObjectName === undefined || mappedObjectName === objectName) {
    return next(req);
  }

  const clonedRequest = req.clone({
    url: req.url.replace(objectName, mappedObjectName),
  });

  return next(clonedRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      console.warn(
        `Error while fetching mapped object ${mappedObjectName} for app ${appName}`,
        error,
      );
      return next(req);
    }),
  );
};
