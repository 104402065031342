import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { PatientResourceMapper } from 'projects/core/src/lib/mappers/patient.mapper';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom, mergeMap, Observable, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, map, retry, shareReplay, take } from 'rxjs/operators';
import { APIError } from '../data/errors.data';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import { TabFormMapper } from '../mappers/tab-form.mapper';
import { TableMapper } from '../mappers/table.mapper';
import { ClientConfig, EndpointDefinition } from '../models/client.model';
import { DynamicButton } from '../models/dynamic-button.model';
import { TableHeaderItem, TableList } from '../models/dynamic-table.model';
import {
  DynamicDataField,
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
  PrefillAttributeNamesMap,
} from '../models/form.model';
import { Invoker, InvokerMethods } from '../models/invoker-body.model';
import { PatientCreationTranslationKeys, TranslationOptions } from '../models/modal-action.model';
import { Patient, PrioritizedPatientDataAttributes } from '../models/patient.model';
import { ProfileSettings } from '../models/profile.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { SDAPIResponseObject } from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import { ClientConfigService } from './client-config.service';
import { OverlayEventRole, PopupService } from './popup.service';
import { ProfileSettingsService } from './profile-settings.service';
import { SDAPIService } from './sdapi.service';
import { TableDataService } from './table-data.service';

@Injectable()
export class PatientService {
  private cachedCurrentPatient: Observable<Patient>;
  private patientCreateInvoker: Invoker;

  constructor(
    private http: HttpClient,
    private sdapiService: SDAPIService,
    private profileSettings: ProfileSettingsService,
    private tabFormMapper: TabFormMapper,
    private tableDataService: TableDataService,
    private dynamicFormMapper: DynamicFormMapper,
    private clientConfigService: ClientConfigService,
    private profileSettingsService: ProfileSettingsService,
    private popupService: PopupService,
  ) {}

  public get patientCreateFormConfiguration(): DynamicFormConfiguration {
    const translationOptions: TranslationOptions = {
      keys: PatientCreationTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return new DynamicFormConfiguration({
      type: DynamicFormType.STATIC_PATIENT_CREATE,
      activityURL: this.patientCreateInvoker.activityURL,
      translationOptions,
      invoker: this.patientCreateInvoker,
    });
  }

  async createPatient(): Promise<string> {
    const modalResult: OverlayEventDetail = await this.popupService.showDynamicFormModal(
      this.patientCreateFormConfiguration,
    );
    if (modalResult.role === OverlayEventRole.save && modalResult.data) {
      const patientId: string = PatientService.retrieveIdOfCreatedPatient(
        modalResult.data.response,
      );
      return patientId;
    }
    return undefined;
  }

  public async getCreatePatientButtonConfiguration(): Promise<DynamicButton> {
    if (await this.shouldShowCreatePatientButton()) {
      return new DynamicButton(
        { extended: 'shared.patients.new-patient', short: 'general.new' },
        null,
        null,
        faPlus,
      );
    } else {
      return null;
    }
  }

  public static retrieveIdOfCreatedPatient(response: SDAPIResponseObject): string {
    return SDAPIObjectMapper.mapCreatedObjId(response).toString();
  }

  public getCurrentPatient(): Observable<Patient> {
    return this.cachedCurrentPatient;
  }

  public async getCurrentPatientId(): Promise<string> {
    return (await firstValueFrom(this.cachedCurrentPatient))?.patientID;
  }

  public setCurrentPatient(patientIdFromRoute?: string): Observable<Patient> {
    const patientId: string =
      patientIdFromRoute || this.profileSettings.getCurrentPatientIdFromCookies();
    if (patientId) {
      return this.switchAndCacheCurrentPatient(patientId);
    } else {
      return this.loadAndCacheMainPatient();
    }
  }

  public async retrieveLastVisitedPatients(patients: Patient[]): Promise<Patient[]> {
    try {
      const patientIdList: string[] = await this.fetchIdsOfLastVisitedPatients();
      return patientIdList
        .map((patientID: string) => this.retrievePatientById(patients, patientID))
        .filter((patient: Patient) => patient);
    } catch (error) {
      console.error(
        'Error retrieving patients by IDs retrieved from the user profile settings.',
        error,
      );
      return [];
    }
  }

  public async resolveCurrentPatient(patientIdFromRoute: string): Promise<Patient> {
    const cachedPatient: Patient =
      this.cachedCurrentPatient && (await firstValueFrom(this.cachedCurrentPatient));

    if (patientIdFromRoute === cachedPatient?.patientID) {
      return cachedPatient;
    } else {
      this.profileSettings.switchPatient(patientIdFromRoute);
      return await firstValueFrom(this.setCurrentPatient(patientIdFromRoute));
    }
  }

  private async fetchIdsOfLastVisitedPatients(): Promise<string[]> {
    const profileSettings: ProfileSettings =
      await this.profileSettingsService.getUserProfileSettingsValue();
    return profileSettings.lastVisitedPatientsIdList;
  }

  private loadAndCacheMainPatient(forceRefresh: boolean = false): Observable<Patient> {
    if (!this.cachedCurrentPatient || forceRefresh) {
      this.cachedCurrentPatient = this.getAllPatients().pipe(
        map((patients) => patients[0]),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    return this.cachedCurrentPatient;
  }

  public switchAndCacheCurrentPatient(patientID: string): Observable<Patient> {
    this.cachedCurrentPatient = this.getAllPatients().pipe(
      map((patients) => this.tryGetPatientFromCookies(patients, patientID)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    return this.cachedCurrentPatient;
  }

  private tryGetPatientFromCookies(patients: Patient[], patientID: string): Patient {
    const patient = patients.find((p) => p.patientID === patientID);
    if (!patient) {
      this.profileSettings.deleteCurrentPatientIdFromCookies();
      return patients[0];
    }
    return patient;
  }

  public getAllPatients(cache: boolean = true): Observable<Patient[]> {
    const customHttpHeaders: string[] = cache ? [] : [CustomHttpHeaders.XNoCache];
    const constructedHttpHeaders: HttpHeaders = CustomHttpHeaders.build(...customHttpHeaders);
    return this.sdapiService
      .getInvokerByMethodName(
        'PP_ALL_PATIENTS',
        InvokerMethods.constraintObjectList,
        constructedHttpHeaders,
      )
      .pipe(
        mergeMap((invoker: Invoker) =>
          this.http
            .put<TieTableObjectList>(invoker.activityURL, invoker.invoker, {
              headers: constructedHttpHeaders,
            })
            .pipe(
              map((response: TieTableObjectList) => PatientResourceMapper.mapResource(response)),
            ),
        ),
        catchError((error) => {
          console.error('Could not fetch all patients', error);
          return of([]);
        }),
      );
  }

  public getPatient(patientID: string): Observable<Patient> {
    return this.findPatient(patientID).pipe(
      map((response: TieTableObjectList) => PatientResourceMapper.mapResource(response)[0]),
    );
  }

  private findPatient(patientID: string): Observable<TieTableObjectList> {
    const dataMap: Map<string, string> = new Map([['TEMP.PATIENT_ID[BODY,1]', patientID]]);

    return this.sdapiService.findDataObjectWithMetaFinder<TieTableObjectList>(
      'PP_FIND_PATIENT_BY_ID',
      InvokerMethods.objectFindInObjectList,
      dataMap,
    );
  }

  public getPatientAttributesNamesMap(patientID: string): Observable<PrefillAttributeNamesMap> {
    return this.sdapiService
      .getInvokerByMethodName(patientID, InvokerMethods.objectAttributesView)
      .pipe(
        concatMap((invoker: Invoker) =>
          this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker),
        ),
        map((resource: TieFormObject) =>
          this.tabFormMapper.mapAttributeNamesValueMapOfDynamicFormTabResource(resource),
        ),
        catchError((error) =>
          throwError(
            () => new APIError('Fetching of Patient Data failed in Patient Service', error),
          ),
        ),
      );
  }

  public getPatientDataViewForm(patientId: string): Observable<DynamicForm> {
    return this.sdapiService
      .getInvokerByMethodName(patientId, InvokerMethods.objectAttributesView)
      .pipe(
        concatMap((invoker: Invoker) =>
          zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
        ),
        map(([form, invoker]) => {
          const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
          return this.processPatientDataViewForm(form, activityURL);
        }),
      );
  }

  private processPatientDataViewForm(form: TieFormObject, activityURL): DynamicForm {
    const dynamicForm: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
      form,
      activityURL,
      false,
      true,
    );
    const patientForm: DynamicForm = TableMapper.addTableDefinitionDetailsToFormItems(dynamicForm);
    return this.mapAndUpdateTableAccordingToUserSettings(patientForm);
  }

  private mapAndUpdateTableAccordingToUserSettings(form: DynamicForm): DynamicForm {
    form.body
      .flatMap((group: DynamicDataField) => group.fieldGroup)
      .forEach((field: DynamicDataField) => {
        if (field.value.table) {
          this.fetchHeaderPreferencesAndUpdateTable(field.value.table);
        }
      });
    return form;
  }

  public async fetchHeaderPreferencesAndUpdateTable(table: TableList): Promise<void> {
    const storedHeaderIdentifiers = await this.tableDataService.fetchTableColumnPreferences(table);
    if (storedHeaderIdentifiers) {
      this.tableDataService.handleTableHeaderUpdateAndSorting(table, storedHeaderIdentifiers);
    } else {
      await this.sortTableHeaderItemsByDefault(table);
      table.sortColumnsHorizontally(table.sortedHeader);
    }
  }

  private async sortTableHeaderItemsByDefault(table: TableList): Promise<void> {
    const prioritizedItems: TableHeaderItem[] = this.getPrioritizedHeaderItems(
      table,
      this.prioritizedTableHeaderIdentifiers,
    );
    const secondaryItems: TableHeaderItem[] = this.getSecondaryHeaderItems(table, prioritizedItems);
    table.sortedHeader = [...prioritizedItems, ...secondaryItems];
  }

  private get prioritizedTableHeaderIdentifiers(): AttributeNameIdentifier[] {
    return [
      PrioritizedPatientDataAttributes.salutation,
      PrioritizedPatientDataAttributes.firstName,
      PrioritizedPatientDataAttributes.lastName,
    ];
  }

  private getPrioritizedHeaderItems(
    table: TableList,
    identifiers: AttributeNameIdentifier[],
  ): TableHeaderItem[] {
    const sortedHeader: TableHeaderItem[] = [];
    for (const identifier of identifiers) {
      const headerItem: TableHeaderItem = table.initialHeader.find((item: TableHeaderItem) =>
        identifier.isEqualTo(item.identifier),
      );
      if (headerItem) {
        sortedHeader.push(headerItem);
      }
    }
    return sortedHeader;
  }

  private getSecondaryHeaderItems(
    table: TableList,
    prioritizedItems: TableHeaderItem[],
  ): TableHeaderItem[] {
    return table.initialHeader.filter((item: TableHeaderItem) => !prioritizedItems.includes(item));
  }

  public getPatientDataEditForm(patientId: string): Observable<DynamicForm> {
    return this.sdapiService
      .getInvokerByMethodName(patientId, InvokerMethods.objectAttributesEdit)
      .pipe(
        concatMap((invoker: Invoker) => {
          if (!invoker) {
            return of(undefined);
          }
          return this.fetchAndMapPatientDataEditForm(invoker);
        }),
        catchError(() => of(undefined)),
      );
  }

  private fetchAndMapPatientDataEditForm(invoker: Invoker): Observable<DynamicForm> {
    return this.http
      .put<TieFormObject>(invoker.activityURL, invoker.invoker)
      .pipe(
        map((response: TieFormObject) =>
          this.dynamicFormMapper.mapDynamicFormResource(
            response,
            SDAPIObjectMapper.mapActivityPath(invoker.invoker),
          ),
        ),
      );
  }

  public checkSetShow(patientId: string): Observable<boolean> {
    return this.sdapiService.checkForInvokerByMethodName(patientId, InvokerMethods.objectSetShow);
  }

  public getSetShowForm(patientId: string): Observable<DynamicForm> {
    return this.sdapiService.getFormByMethodFromObjectMenu(
      InvokerMethods.objectSetShow,
      `/objects/${patientId}`,
    );
  }

  public retrieveRecentlyCreatedPatient(patientId: string): Promise<Patient> {
    return new Promise((resolve, reject) =>
      this.getAllPatients(false)
        .pipe(
          take(1),
          concatMap((patientList: Patient[]) =>
            this.attemptRetrievalOfCreatedPatient(patientList, patientId),
          ),
          retry({ count: 13, delay: 1500 }),
        )
        .subscribe({
          next: (patient: Patient) => resolve(patient),
          error: (error: Error) => {
            console.error(`Patient with ObjectID [${patientId}] not found.`);
            reject(error);
          },
        }),
    );
  }

  private attemptRetrievalOfCreatedPatient(
    patientList: Patient[],
    patientId: string,
  ): Observable<Patient> {
    const createdPatient: Patient = this.retrievePatientById(patientList, patientId);
    if (createdPatient) {
      return of(createdPatient);
    } else {
      return throwError(() => new Error());
    }
  }

  public retrievePatientById(patients: Patient[], patientID: string): Patient {
    return patients.find((patient: Patient) => patient.patientID === patientID);
  }

  public async shouldShowCreatePatientButton(): Promise<boolean> {
    const endpointDefinition: EndpointDefinition = this.retrievePatientCreateEndpointDefinition();
    if (!endpointDefinition) {
      return false;
    }

    const invoker: Invoker = await this.fetchPatientCreateInvoker(endpointDefinition);
    return !!invoker;
  }

  private retrievePatientCreateEndpointDefinition(): EndpointDefinition {
    const clientConfig: ClientConfig = this.clientConfigService.get();
    const endpointDefinition: EndpointDefinition = clientConfig.apiConfig?.patientCreate;
    if (!endpointDefinition?.objectId) {
      return null;
    }

    return endpointDefinition;
  }

  private async fetchPatientCreateInvoker(
    endpointDefinition: EndpointDefinition,
  ): Promise<Invoker> {
    try {
      const invoker: Invoker = await firstValueFrom(
        this.sdapiService.getInvokerByMethodName(
          `${endpointDefinition.objectId}`,
          InvokerMethods.patientCreate,
        ),
      );
      this.patientCreateInvoker = invoker;
      return invoker;
    } catch {
      return null;
    }
  }
}
