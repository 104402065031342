<lib-auto-layout class="patient-header shape-card" vertical="stretch">
  <div class="toolbar-item card-layout">
    <ion-button
      [id]="popoverTriggerId"
      color="tertiary"
      class="ion-no-margin"
      [disabled]="!menu"
      (click)="showPatientMenuPopover()">
      <span class="p3">{{ 'general.menu' | translate }}</span>
      @if (menu) {
        <fa-icon slot="end" icon="chevron-down"></fa-icon>
      } @else {
        <fa-icon slot="end" icon="spinner" animation="spin-pulse"></fa-icon>
      }
    </ion-button>
  </div>

  <lib-auto-layout
    vertical="center"
    horizontal="stretch"
    [grow]="1"
    [padding]="[7, 15]"
    [gap]="[8]">
    @if (patient) {
      <span class="p name trim-text-inline"> {{ patient.lastName }}, {{ patient.firstName }} </span>
      <lib-auto-layout vertical="center">
        @if (patient.birthDate) {
          <span class="p">{{ patient.birthDate | localeDate: 'shortDate' }}</span
          >&nbsp;
          <span class="p ion-hide-md-down">({{ patient | ageCount }})</span>
        }
      </lib-auto-layout>
    } @else {
      <ion-skeleton-text [grow]="1" class="rounded-small" animated></ion-skeleton-text>
    }
  </lib-auto-layout>
</lib-auto-layout>
