@if (shouldGroupButtonsInActionSheet()) {
  <lib-dynamic-action-sheet
    class="stretch-self"
    [config]="config"
    (actionTrigger)="actionTrigger.emit($event)">
  </lib-dynamic-action-sheet>
} @else {
  <lib-auto-layout class="button-container" [gap]="[10]" horizontal="end">
    @for (button of config.actionButtons; track button) {
      @if (button.invoker) {
        @if (button.hasDeleteActivity) {
          <ion-button
            class="color-warn ion-text-wrap ion-no-margin"
            [disabled]="config.triggerButton.disabled"
            color="warn"
            [attr.data-api-id]="config.triggerButton.dataApiId"
            (click)="actionTrigger.emit(button.invoker)">
            <span class="p3 ion-text-center">
              {{ button.label.extended }}
            </span>
            <fa-icon slot="end" icon="trash-can"></fa-icon>
          </ion-button>
        } @else {
          <ion-button
            class="ion-text-wrap ion-no-margin"
            [disabled]="config.triggerButton.disabled"
            color="primary"
            [attr.data-api-id]="config.triggerButton.dataApiId"
            (click)="actionTrigger.emit(button.invoker)">
            <span class="p3 ion-text-center">
              {{ button.label.extended }}
            </span>
          </ion-button>
        }
      }

      @if (button.hasDownloadActivity) {
        <ion-button
          class="ion-text-wrap ion-no-margin"
          [disabled]="config.triggerButton.disabled"
          color="tertiary"
          [attr.data-api-id]="config.triggerButton.dataApiId"
          (click)="downloadFile(button.downloadDetails)">
          <span class="p3 ion-text-center">{{ button.label.extended }}</span>
          <fa-icon slot="end" icon="cloud-arrow-down"></fa-icon>
        </ion-button>
      }

      @if (button.hasPrintActivity && labelService.isPrintCapableDevice()) {
        <lib-label-print-button [button]="button"></lib-label-print-button>
      }
    }
  </lib-auto-layout>
}
