<div class="appointment-container">
  <ion-list lines="none" class="appointment-details">
    <div class="details-header">
      <ion-item>
        <fa-icon size="lg" slot="start" [icon]="icons.clock"></fa-icon>
        <p class="ion-no-padding ion-no-margin h3 flex-grow">
          @if (appointment.isAllDay) {
            {{ 'shared.appointment-item.all-day' | translate }}
          } @else {
            {{ appointment.startDate | time: 'shortTime' : true : true }}
          }
        </p>
        <div slot="end" class="appointment-status" *ngIf="appointment.status">
          <div class="appointment-status-wrapper">
            <div
              class="appointment-status-indicator"
              [style.background]="appointment.status.hexColor"></div>
            <p class="h3">{{ appointment.status.label }}</p>
          </div>
        </div>
      </ion-item>

      <ion-item>
        <fa-icon size="lg" slot="start" [icon]="icons.info"></fa-icon>
        <p class="ion-no-padding ion-no-margin flex-grow">
          {{ appointment.title }}
        </p>
      </ion-item>
    </div>

    <div class="sub-details">
      <ion-item>
        <fa-icon size="lg" slot="start" [icon]="icons.calendar"></fa-icon>
        <p class="ion-no-padding ion-no-margin">
          {{ appointment.startDate | localeDate: 'shortDate' }}
        </p>
      </ion-item>

      <ion-item *ngIf="appointment.doctor">
        <fa-icon size="lg" slot="start" [icon]="icons.doctor"></fa-icon>
        <p class="ion-no-padding ion-no-margin">{{ appointment.doctor }}</p>
      </ion-item>

      <ion-item *ngIf="appointment.patient">
        <fa-icon size="lg" slot="start" [icon]="icons.patient"></fa-icon>
        <p class="ion-no-padding ion-no-margin">{{ appointment.patient }}</p>
      </ion-item>

      <ion-item *ngIf="appointment.location">
        <fa-icon size="lg" slot="start" [icon]="icons.clinic"></fa-icon>
        <p class="ion-no-padding ion-no-margin">{{ appointment.location }}</p>
      </ion-item>
    </div>
  </ion-list>

  <div class="no-details" *ngIf="hasNoDetails(appointment)">
    <fa-icon size="xl" [icon]="icons.info"></fa-icon>
    <p>{{ 'shared.appointment-details.no-more-information' | translate }}</p>
  </div>

  <div class="button-section">
    <div *ngIf="isLoading" class="appointment-buttons-skeleton">
      <div class="skeleton-item" *ngFor="let item of [0, 0]">
        <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
      </div>
    </div>

    <lib-auto-layout
      *ngIf="!isLoading"
      [direction]="'vertical'"
      [horizontal]="'stretch'"
      class="ion-padding">
      @if (appointment.type !== appointmentType.REMOTE) {
        @if (updateButtons) {
          @if (breakPoint.isBelow('md') && updateButtons.length > 2) {
            <lib-dynamic-action-sheet
              class="stretch-self"
              [config]="actionSheetConfig"
              (actionTrigger)="openFormForAppointmentUpdateModal($event)">
            </lib-dynamic-action-sheet>
          } @else {
            @for (button of updateButtons; track button) {
              <ion-button
                color="tertiary"
                (click)="openFormForAppointmentUpdateModal(button.invoker)">
                <fa-icon slot="start" [icon]="icons.pen"></fa-icon>
                {{ button.label.extended }}
              </ion-button>
            }
          }
        }

        @if (appointment.clinicPhoneNumber) {
          <ion-button color="tertiary" href="tel:{{ appointment.clinicPhoneNumber }}">
            <fa-icon slot="start" [icon]="icons.telephone"></fa-icon>
            {{ appointment.clinicPhoneNumber }}
          </ion-button>
        }
      }

      @if (appointment.type === appointmentType.REMOTE) {
        <ion-button color="tertiary" target="_blank" [href]="videoCallUrl">
          <fa-icon slot="start" [icon]="icons.video"></fa-icon>
          {{ 'shared.appointment-details.start-video-call' | translate }}
        </ion-button>
      }

      @if (icsFileString) {
        <ion-button color="tertiary" (click)="downloadIcsFile()">
          <fa-icon slot="start" [icon]="icons.cloudArrowDown"></fa-icon>
          {{ 'shared.appointment-details.export-appointment' | translate }}
        </ion-button>
      }

      @if (treatmentId) {
        <ion-button color="tertiary" (click)="navigateToTreatmentDetails()">
          {{ 'shared.appointment-overview.treatment-details' | translate }}
          <fa-icon [icon]="icons.chevronRight" slot="end"></fa-icon>
        </ion-button>
      }
    </lib-auto-layout>
  </div>
</div>
