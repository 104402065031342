import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClientAuthCockpitMode, ClientConfig } from 'projects/core/src/lib/models/client.model';
import { DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import {
  doctorProfileAccountInfoFieldKeys,
  doctorProfileContactInfoFieldKeys,
  patientProfileAccountInfoFieldKeys,
  patientProfileContactInfoFieldKeys,
  Profile,
  ProfileFieldKey,
  ProfileSection,
} from 'projects/core/src/lib/models/profile.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { ProfileFormService } from 'projects/core/src/lib/services/profile-form.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';

@Component({
  selector: 'lib-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
@UntilDestroy()
export class ProfileViewComponent implements OnInit {
  @Output() dataUpdate: EventEmitter<void> = new EventEmitter<void>();

  @Input() isLoading = true;
  @Input() profile: Profile = new Profile();
  @Input() userDeactivationForm: DynamicForm;
  @Input() profileEditForm: DynamicForm;
  @Input() isDoctor: boolean;

  hintMessageActive: boolean;
  changePasswordLink: string;
  authCockpitLink: string;
  openAuthCockpitBlank = false;

  ProfileSection: typeof ProfileSection = ProfileSection;

  readonly subscriptionGroupIndex = 2;
  readonly furtherSubscriptionsGroupIndex = 3;

  constructor(
    private profileFormService: ProfileFormService,
    private clientService: ClientConfigService,
    private authService: AuthService,
    private languageService: LanguageService,
    public profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.setDataForPasswordEditing();
  }

  private setDataForPasswordEditing(): void {
    const clientConfig: ClientConfig = this.clientService.get();
    if (clientConfig) {
      this.changePasswordLink = clientConfig.authConfig.changePasswordLink;
      this.hintMessageActive = clientConfig.authConfig.passwordHintMessageActive;
      this.authCockpitLink = this.authService.buildAuthCockpitUrl(
        clientConfig,
        this.languageService.getCurrentLanguageCode(),
      );
      this.openAuthCockpitBlank =
        clientConfig.authConfig.authCockpitMode === ClientAuthCockpitMode.NEW_TAB;
    }
  }

  get accountInfoFieldKeys(): ProfileFieldKey[] {
    return this.isDoctor ? doctorProfileAccountInfoFieldKeys : patientProfileAccountInfoFieldKeys;
  }

  get contactInfoFieldKeys(): ProfileFieldKey[] {
    return this.isDoctor ? doctorProfileContactInfoFieldKeys : patientProfileContactInfoFieldKeys;
  }

  get subscriptionInfoFormGroup(): DynamicDataField {
    return this.profile.detailsForm?.body[this.subscriptionGroupIndex];
  }

  getFilteredViewFields(fieldKeys: ProfileFieldKey[], groupIndex?: number): DynamicDataField[] {
    if (!this.profile.detailsForm) {
      return [];
    }
    return this.profileFormService
      .filterFormFields(this.profile.detailsForm, fieldKeys, groupIndex)
      .flatMap((group: DynamicDataField) => group.fieldGroup);
  }

  hasFormSectionViewableFields(fieldKeys: ProfileFieldKey[], groupIndex?: number): boolean {
    const fields: DynamicDataField[] = this.getFilteredViewFields(fieldKeys, groupIndex);
    return fields.some((item: DynamicDataField) => item.isDisplayable);
  }

  private getFilteredEditableFields(
    fieldKeys: ProfileFieldKey[],
    groupIndex?: number,
  ): DynamicDataField[] {
    if (!this.profileEditForm) {
      return [];
    }
    return this.profileFormService
      .filterFormFields(this.profileEditForm, fieldKeys, groupIndex)
      .flatMap((group: DynamicDataField) => group.fieldGroup);
  }

  hasFormSectionEditableFields(fieldKeys: ProfileFieldKey[], groupIndex?: number): boolean {
    const fields: DynamicDataField[] = this.getFilteredEditableFields(fieldKeys, groupIndex);
    return fields.some((item: DynamicDataField) => item.isEditable);
  }

  updateProfileData(): void {
    this.dataUpdate.emit();
  }

  isSectionVisible(section: ProfileSection): boolean {
    if (this.isLoading) {
      return true;
    }
    return this.resolveSectionVisibility(section);
  }

  private resolveSectionVisibility(section: ProfileSection): boolean {
    switch (section) {
      case ProfileSection.contactInfo:
        return this.hasFormSectionViewableFields(this.contactInfoFieldKeys);
      case ProfileSection.accountInfo:
        return (
          this.hasFormSectionViewableFields(this.accountInfoFieldKeys) ||
          this.isAccountManagementEnabled
        );
      case ProfileSection.subscriptionInfo:
        return this.hasFormSectionViewableFields(undefined, this.subscriptionGroupIndex);
      case ProfileSection.furtherSubscriptionInfo:
        return this.profileService.profileSubscriptionElements?.length > 0;
      default:
        return false;
    }
  }

  private get isAccountManagementEnabled(): boolean {
    return !!this.userDeactivationForm || !!this.authCockpitLink || !!this.changePasswordLink;
  }
}
