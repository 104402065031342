import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { LaboratoryResultsMapper } from 'projects/core/src/lib/mappers/laboratory-results.mapper';
import { GraphDataSet } from 'projects/core/src/lib/models/graph.model';
import {
  LaboratoryResultTable,
  LaboratoryResultTableCell,
  LaboratoryResultTableColumn,
  LaboratoryResultTableRow,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { take } from 'rxjs';

@Component({
  selector: 'lib-laboratory-results-table',
  templateUrl: './laboratory-results-table.component.html',
  styleUrl: './laboratory-results-table.component.scss',
})
export class LaboratoryResultsTableComponent {
  @ViewChild('overlayContainer') overlayContainer: ElementRef;
  @Input() tableData!: LaboratoryResultTable;

  showMoreNoteDetails: boolean = false;

  private activeRowIndex: number;
  private activeColumnIndex: number;

  constructor(
    private readonly popupService: PopupService,
    private readonly ngZone: NgZone,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  escapeNewLine(value: string) {
    return value.replace(/\\n/g, '\n');
  }

  getInfoIconContainerStyles(cell: LaboratoryResultTableCell, isPopupLayout?: boolean): object {
    if (!cell.flag?.color || isPopupLayout) {
      return { border: '1px solid var(--color-greyish-100)' };
    }
    return {};
  }

  getInfoIconStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: `color-mix(in oklab, black 10%, ${cell.flag.color})`,
      };
    }
    return {};
  }

  getIndicatorContainerStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: 'var(--color-white)',
        background: `color-mix(in oklab, black 10%, ${cell.flag.color})`,
      };
    }
    return {};
  }

  getCellStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: `color-mix(in oklab, black 40%, ${cell.flag.color})`,
        background: `color-mix(in oklab, var(--color-white) 80%, ${cell.flag.color})`,
        borderLeft: '1px solid var(--color-greyish-4)',
      };
    }
    return {};
  }

  getDataCellStyles(cell: LaboratoryResultTableCell): object {
    if (cell.notes?.length && cell.value) {
      return { borderLeft: '1px solid var(--color-greyish-4)' };
    }
    return {};
  }

  handleOverlayLayout(connectedOverlay: CdkConnectedOverlay): void {
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      const popoverElement: HTMLElement = this.getPopoverElement(connectedOverlay);
      if (popoverElement) {
        this.adjustNoteDetailsView(popoverElement);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  async showNotes(notes: string[]): Promise<void> {
    await this.popupService.showLaboratoryNotesPopover(notes);
  }

  isOverlayOpen(rowIndex: number, columnIndex: number): boolean {
    return this.activeRowIndex === rowIndex && this.activeColumnIndex === columnIndex;
  }

  showInfoOverlay(rowIndex: number, columnIndex: number): void {
    this.activeRowIndex = rowIndex;
    this.activeColumnIndex = columnIndex;
  }

  dismissInfoOverlay(event: any, connectedOverlay: CdkConnectedOverlay): void {
    if (this.overlayContains(connectedOverlay, this.overlayContainer, event)) {
      return;
    }

    this.activeRowIndex = undefined;
    this.activeColumnIndex = undefined;
  }

  private overlayContains(
    connectedOverlay: CdkConnectedOverlay,
    overlayContainer: ElementRef,
    event: any,
  ): boolean {
    const overlayElement: HTMLElement = connectedOverlay.overlayRef.overlayElement;
    return (
      overlayElement.contains(event['target']) ||
      overlayContainer.nativeElement.contains(event['target'])
    );
  }

  private getPopoverElement(connectedOverlay: CdkConnectedOverlay): HTMLElement | null {
    const overlayElement: HTMLElement = connectedOverlay.overlayRef.overlayElement;
    return overlayElement.querySelector('.popover-wrapper');
  }

  private adjustNoteDetailsView(popoverElement: HTMLElement): void {
    const contentHeight: number = popoverElement.offsetHeight;
    if (contentHeight >= 250) {
      this.showMoreNoteDetails = true;
      popoverElement.classList.add('fade-effect');
    } else {
      this.showMoreNoteDetails = false;
      popoverElement.classList.remove('fade-effect');
    }
  }

  isRowEmpty(row: LaboratoryResultTableRow): boolean {
    return row.cells.every(
      (cell: LaboratoryResultTableCell) => !cell.value || isNaN(Number(cell.value)),
    );
  }

  async openGraphModal(
    header: LaboratoryResultTableColumn[],
    dataCells: LaboratoryResultTableCell[],
    fixedCells: LaboratoryResultTableCell[],
  ): Promise<void> {
    const title: string = LaboratoryResultsMapper.constructModalTitle(fixedCells);
    const dataSet: GraphDataSet = LaboratoryResultsMapper.mapTableDataRowForGraphView(
      header,
      dataCells,
      this.tableData.fixedColumns,
      fixedCells,
    );
    await this.popupService.showGraphModal(dataSet, title);
  }
}
