import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgIdleModule } from '@ng-idle/core';
import { TranslatePipe } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CoreModule } from 'projects/core/src/lib/core.module';
import { AftercareDetailsComponent } from 'projects/shared/src/lib/components/aftercare/aftercare-details/aftercare-details.component';
import { AftercareComponent } from 'projects/shared/src/lib/components/aftercare/aftercare.component';
import { AppointmentsOverviewComponent } from 'projects/shared/src/lib/components/appointments-overview/appointments-overview.component';
import { AvatarComponent } from 'projects/shared/src/lib/components/avatar/avatar.component';
import { DocumentThumbnailComponent } from 'projects/shared/src/lib/components/document-thumbnail/document-thumbnail.component';
import { DynamicActionSheetComponent } from 'projects/shared/src/lib/components/dynamic-action-sheet/dynamic-action-sheet.component';
import { ExpensesComponent } from 'projects/shared/src/lib/components/expenses/expenses.component';
import { DateFieldDatePopoverComponent } from 'projects/shared/src/lib/components/form/date-field/date-field-date-popover/date-field-date-popover.component';
import { DateFieldTimePopoverComponent } from 'projects/shared/src/lib/components/form/date-field/date-field-time-popover/date-field-time-popover.component';
import { LaboratoryResultsTableComponent } from 'projects/shared/src/lib/components/laboratory-results/laboratory-results-table/laboratory-results-table.component';
import { LaboratoryResultsComponent } from 'projects/shared/src/lib/components/laboratory-results/laboratory-results.component';
import { LandingpageComponent } from 'projects/shared/src/lib/components/landingpage/landingpage.component';
import { TestimonialCardComponent } from 'projects/shared/src/lib/components/landingpage/testimonial-card/testimonial-card.component';
import { MedicalCertificatesComponent } from 'projects/shared/src/lib/components/medical-certificates/medical-certificates.component';
import { LinkViewerComponent } from 'projects/shared/src/lib/components/modals/document-viewer/link/link.component';
import { TermsOfServiceButtonComponent } from 'projects/shared/src/lib/components/terms-of-service-button/terms-of-service-button.component';
import { TermsOfServiceViewerComponent } from 'projects/shared/src/lib/components/terms-of-service-viewer/terms-of-service-viewer.component';
import { CustomMenuService } from 'projects/shared/src/lib/services/custom-menu.service';
import { InactivityService } from 'projects/shared/src/lib/services/inactivity.service';
import { ThemeModule } from 'projects/theme/src/lib/theme.module';
import { ActionButtonsContainerComponent } from './components/action-buttons-toolbar/action-buttons-container/action-buttons-container.component';
import { ActionButtonsToolbarComponent } from './components/action-buttons-toolbar/action-buttons-toolbar.component';
import { AppVersionComponent } from './components/app-version/app-version.component';
import { BookingModalComponent } from './components/appointment-booking/booking-modal/booking-modal.component';
import { PublicBookingModalComponent } from './components/appointment-booking/public-booking-modal/public-booking-modal.component';
import { DateSelectionFieldComponent } from './components/appointment-booking/sub-components/date-selection-field.component';
import { DateTimePopoverComponent } from './components/appointment-booking/sub-components/date-time-popover.component';
import { DynamicDateSelection } from './components/appointment-booking/sub-components/dynamic-date.selection.component';
import { FormInput } from './components/appointment-booking/sub-components/form-input.component';
import { ResultRow } from './components/appointment-booking/sub-components/result-row.component';
import { TimelineIndicator } from './components/appointment-booking/sub-components/timeline-indicator.component';
import { BookingUpcommingList } from './components/appointment-booking/sub-components/upcomming-list.component';
import { AppointmentDateDividerComponent } from './components/appointment-date-divider/appointment-date-divider.component';
import { AppointmentDetailsComponent } from './components/appointment-details/appointment-details.component';
import { AppointmentItemComponent } from './components/appointment-item/appointment-item.component';
import { AppointmentsListComponent } from './components/appointments-list/appointments-list.component';
import { AppointmentsScrollListComponent } from './components/appointments-scroll-list/appointments-scroll-list.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { AutoIdentWidgetComponent } from './components/dashboard-widgets/auto-ident/auto-ident.component';
import { DocumentsWidgetComponent } from './components/dashboard-widgets/documents/documents.component';
import { ExpenseRowComponent } from './components/dashboard-widgets/expenses/expense-row/expense-row.component';
import { ExpensesWidgetComponent } from './components/dashboard-widgets/expenses/expenses.component';
import { LastActivitiesComponent } from './components/dashboard-widgets/last-activities/last-activities.component';
import { MedicalCertificateRowComponent } from './components/dashboard-widgets/medical-certificates/medical-certificate-row/medical-certificate-row.component';
import { MedicalCertificatesWidgetComponent } from './components/dashboard-widgets/medical-certificates/medical-certificates.component';
import { NextAppointmentsComponent } from './components/dashboard-widgets/next-appointments/next-appointments.component';
import { RequiredActionsWidgetComponent } from './components/dashboard-widgets/required-actions/required-actions.component';
import { RequiredActionCardComponent } from './components/dashboard-widgets/required-actions/sub-components/required-action-card.component';
import { TreatmentsWidgetComponent } from './components/dashboard-widgets/treatments/treatments.component';
import { DataExchangeFormComponent } from './components/data-exchange-form/data-exchange-form.component';
import { DataOrganizerToolboxComponent } from './components/data-organizer-toolbox/data-organizer-toolbox.component';
import { HorizontalSortingEditModeComponent } from './components/data-organizer-toolbox/horizontal-sorting-section/horizontal-sorting-edit-mode/horizontal-sorting-edit-mode.component';
import { HorizontalSortingSectionComponent } from './components/data-organizer-toolbox/horizontal-sorting-section/horizontal-sorting-section.component';
import { HorizontalSortingViewModeComponent } from './components/data-organizer-toolbox/horizontal-sorting-section/horizontal-sorting-view-mode/horizontal-sorting-view-mode.component';
import { OrganizerListItemComponent } from './components/data-organizer-toolbox/organizer-list-item/organizer-list-item.component';
import { VerticalSortingSectionComponent } from './components/data-organizer-toolbox/vertical-sorting-section/vertical-sorting-section.component';
import { DocumentDetailsViewerComponent } from './components/document-details-viewer/document-details-viewer.component';
import { DocumentDetailsPopoverContentComponent } from './components/document-download-popover/document-details-popover-content/document-details-popover-content.component';
import { DocumentDetailsPopoverComponent } from './components/document-download-popover/document-details-popover.component';
import { DocumentRowComponent } from './components/document-row/document-row.component';
import { DocumentsBrowserComponent } from './components/documents-browser/documents-browser.component';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { DocumentsSkeletonComponent } from './components/documents-skeleton/documents-skeleton.component';
import { FiltersContextComponent } from './components/filters-context/filters-context.component';
import { AutocompleteFieldComponent } from './components/form/autocomplete-field/autocomplete-field.component';
import { BaseFieldComponent } from './components/form/base-field/base-field.component';
import { CheckboxFieldComponent } from './components/form/checkbox-field/checkbox-field.component';
import { DateFieldSelectTimeOrDatePopoverComponent } from './components/form/date-field/date-field-select-time-or-date-popover/date-field-select-time-or-date-popover.component';
import { DateFieldComponent } from './components/form/date-field/date-field.component';
import { DynamicFormCompactLayoutComponent } from './components/form/dynamic-form-compact-layout/dynamic-form-compact-layout.component';
import { DynamicFormFlexibleLayoutComponent } from './components/form/dynamic-form-flexible-layout/dynamic-form-flexible-layout.component';
import { FormNavigationComponent } from './components/form/dynamic-form-flexible-layout/form-navigation/form-navigation.component';
import { FieldContainerComponent } from './components/form/field-container/field-container.component';
import { FileInputFieldComponent } from './components/form/file-input-field/file-input-field.component';
import { MenuButtonContentComponent } from './components/form/menu-button-content/menu-button-content.component';
import { MultiChosenComponent } from './components/form/multi-chosen/multi-chosen.component';
import { NumberFieldComponent } from './components/form/number-field/number-field.component';
import { PreviewFormComponent } from './components/form/preview-form/preview-form.component';
import { RichtextComponent } from './components/form/richtext/richtext.component';
import { SelectionFieldPopoverComponent } from './components/form/selection-field-popover/selection-field-popover.component';
import { SelectionFieldComponent } from './components/form/selection-field/selection-field.component';
import { TextAreaFieldComponent } from './components/form/text-area-field/text-area-field.component';
import { TextFieldComponent } from './components/form/text-field/text-field.component';
import { TitleFieldComponent } from './components/form/title-field/title-field.component';
import { NotificationBoxComponentComponent } from './components/helper/notification-box-component/notification-box-component.component';
import { SectionDividerComponent } from './components/helper/section-divider/section-divider.component';
import { IheDocumentsBrowserComponent } from './components/ihe-documents-browser/ihe-documents-browser.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LabelPrintButtonComponent } from './components/label-print-button/label-print-button.component';
import { LaboratoryNotesModalComponent } from './components/laboratory-results/laboratory-results-table/laboratory-notes/laboratory-notes-modal.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { AutoLayoutComponent } from './components/layout-helper/auto-layout/auto-layout.component';
import { ButtonContainerComponent } from './components/layout-helper/button-container/button-container.component';
import { HeaderComponent } from './components/modal-helper/header/header.component';
import { IframeComponent } from './components/modal-helper/iframe/iframe.component';
import { InlineLoaderComponent } from './components/modal-helper/inline-loader/inline-loader.component';
import { ModalComponent } from './components/modal-helper/modal/modal.component';
import { AutoIdentIframeComponent } from './components/modals/auto-ident-iframe/auto-ident-iframe.component';
import { AutoIdentInformationComponent } from './components/modals/auto-ident-information/auto-ident-information.component';
import { ChatComponent } from './components/modals/chat/chat.component';
import { DataOrganizerComponent } from './components/modals/data-organizer/data-organizer.component';
import { DocumentViewerComponent } from './components/modals/document-viewer/document-viewer.component';
import { DownloadViewerComponent } from './components/modals/document-viewer/download/download.component';
import { IframeViewerComponent } from './components/modals/document-viewer/iframe/iframe.component';
import { ImageViewerComponent } from './components/modals/document-viewer/image/image.component';
import { PdfViewerComponent } from './components/modals/document-viewer/pdf/pdf.component';
import { VideoViewerComponent } from './components/modals/document-viewer/video/video.component';
import { DynamicTableSelectComponent } from './components/modals/dynamic-table-select/dynamic-table-select.component';
import { FurtherSubscriptionsUpdateComponent } from './components/modals/further-subscriptions-update/further-subscriptions-update.component';
import { GenericTableComponent } from './components/modals/generic-table/generic-table.component';
import { GraphDataViewerComponent } from './components/modals/graph-data-viewer/graph-data-viewer.component';
import { RequiredActionInfoOverviewComponent } from './components/modals/required-action-info-overview/required-action-info-overview.component';
import { RequiredActionSwitchComponent } from './components/modals/required-action-switch/required-action-switch.component';
import { RequiredActionComponent } from './components/modals/required-action/required-action.component';
import { TableSelectComponent } from './components/modals/table-select/table-select.component';
import { NavigationMenuPopoverComponent } from './components/navigation-menu-popover/navigation-menu-popover.component';
import { SwiperContainerComponent } from './components/onboarding-swiper/swiper-container/swiper-container.component';
import { SwiperSlideContentComponent } from './components/onboarding-swiper/swiper-container/swiper-slide-content/swiper-slide-content.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PatientDataViewWrapperComponent } from './components/patient-data-view-wrapper/patient-data-view-wrapper.component';
import { PatientDetailsHeaderComponent } from './components/patient-data-view-wrapper/patient-details-header/patient-details-header.component';
import { PatientDataBrowserComponent } from './components/patient-data/patient-data-browser/patient-data-browser.component';
import { PatientDataTableComponent } from './components/patient-data/patient-data-table/patient-data-table.component';
import { PatientListDataComponent } from './components/patient-data/patient-list-data/patient-list-data.component';
import { PatientTableDetailsViewerComponent } from './components/patient-data/patient-table-details-viewer/patient-table-details-viewer.component';
import { PatientResultRowComponent } from './components/patient-result-row/patient-result-row.component';
import { PatientSearchComponent } from './components/patient-search/patient-search.component';
import { PatientSummaryBrowserComponent } from './components/patient-summary-browser/patient-summary-browser.component';
import { PatientSummaryHeaderComponent } from './components/patient-summary-browser/patient-summary-header/patient-summary-header.component';
import { PatientSummaryListComponent } from './components/patient-summary-browser/patient-summary-list/patient-summary-list.component';
import { PinchZoomComponent } from './components/pinch-zoom/pinch-zoom.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { AccountInformationComponent } from './components/profile-view/account-information/account-information.component';
import { AutoIdentSectionComponent } from './components/profile-view/account-information/auto-ident-section/auto-ident-section.component';
import { ContactInfoCardComponent } from './components/profile-view/contact-information/contact-information.component';
import { FurtherSubscriptionsComponent } from './components/profile-view/further-subscriptions/further-subscriptions.component';
import { ProfileCardFieldComponent } from './components/profile-view/profile-card-field/profile-card-field.component';
import { ProfileCardWrapperComponent } from './components/profile-view/profile-card-wrapper/profile-card-wrapper.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { SubscriptionInformationComponent } from './components/profile-view/subscription-information/subscription-information.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { TabBarPortalMenuComponent } from './components/tab-bar-portal-menu/tab-bar-portal-menu.component';
import { GenericTableContainerComponent } from './components/tables/generic-table-container/generic-table-container.component';
import { GenericTableHeaderComponent } from './components/tables/generic-table-header/generic-table-header.component';
import { GenericTableRowSkeletonComponent } from './components/tables/generic-table-row-skeleton/generic-table-row-skeleton.component';
import { GenericCondensedRowMobileComponent } from './components/tables/generic-table-row/generic-condensed-row-mobile/generic-condensed-row-mobile.component';
import { GenericExpandableRowMobileComponent } from './components/tables/generic-table-row/generic-expandable-row-mobile/generic-expandable-row-mobile.component';
import { GenericTableRowComponent } from './components/tables/generic-table-row/generic-table-row.component';
import { DataOrganizerTriggerButtonComponent } from './components/tables/generic-table-toolbar/data-organizer-trigger-button/data-organizer-trigger-button.component';
import { GenericTableToolbarComponent } from './components/tables/generic-table-toolbar/generic-table-toolbar.component';
import { TaskRowComponent } from './components/task-row/task-row.component';
import { TasksBrowserComponent } from './components/tasks-browser/tasks-browser.component';
import { TasksBrowserComponentSkeleton } from './components/tasks-browser/tasks-browser.component.skeleton';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TermsOfServiceBrowserComponent } from './components/terms-of-service-browser/terms-of-service-browser.component';
import { ThemePreviewComponent } from './components/theme-preview/theme-preview.component';
import { ThemerComponent } from './components/themer/themer.component';
import { DocumentSectionComponent } from './components/treatment-details/document-section/document-section.component';
import { InformationCardComponent } from './components/treatment-details/information-section/information-card/information-card.component';
import { InformationDocumentRowComponent } from './components/treatment-details/information-section/information-card/information-document-row/information-document-row.component';
import { InformationItemComponent } from './components/treatment-details/information-section/information-item/information-item.component';
import { InformationSectionComponent } from './components/treatment-details/information-section/information-section.component';
import { ShareTreatmentWithDoctorComponent } from './components/treatment-details/share-treatment-with-doctor/share-treatment-with-doctor.component';
import { TreatmentDetailsComponent } from './components/treatment-details/treatment-details.component';
import { TreatmentsBrowserComponent } from './components/treatment-list/treatments-browser/treatments-browser.component';
import { TreatmentsTableComponent } from './components/treatment-list/treatments-table/treatments-table.component';
import { TreatmentRowComponent } from './components/treatment-row/treatment-row.component';
import { PatientMenuService } from './services/patient-menu.service';

const MODULES = [
  ThemeModule,
  CoreModule,
  PdfViewerModule,
  FullCalendarModule,
  NgIdleModule.forRoot(),
  ReactiveFormsModule,
  FormsModule,
];

const COMPONENTS = [
  AccountInformationComponent,
  ActionButtonsContainerComponent,
  ActionButtonsToolbarComponent,
  AftercareComponent,
  AftercareDetailsComponent,
  AppointmentDateDividerComponent,
  AppointmentDetailsComponent,
  AppointmentItemComponent,
  AppointmentsListComponent,
  AppointmentsOverviewComponent,
  AppointmentsScrollListComponent,
  AppVersionComponent,
  AutocompleteFieldComponent,
  AutoIdentIframeComponent,
  AutoIdentInformationComponent,
  AutoIdentSectionComponent,
  AutoIdentWidgetComponent,
  AutoLayoutComponent,
  AvatarComponent,
  BaseFieldComponent,
  BookingModalComponent,
  BookingUpcommingList,
  ButtonContainerComponent,
  CardListComponent,
  ChatComponent,
  CheckboxFieldComponent,
  ContactInfoCardComponent,
  DataExchangeFormComponent,
  DataOrganizerComponent,
  DataOrganizerToolboxComponent,
  DataOrganizerTriggerButtonComponent,
  DateFieldComponent,
  DateFieldDatePopoverComponent,
  DateFieldSelectTimeOrDatePopoverComponent,
  DateFieldTimePopoverComponent,
  DateSelectionFieldComponent,
  DateTimePopoverComponent,
  DocumentDetailsPopoverComponent,
  DocumentDetailsPopoverContentComponent,
  DocumentDetailsViewerComponent,
  DocumentRowComponent,
  DocumentsBrowserComponent,
  DocumentSectionComponent,
  DocumentsListComponent,
  DocumentsSkeletonComponent,
  DocumentsWidgetComponent,
  DocumentThumbnailComponent,
  DocumentViewerComponent,
  DownloadViewerComponent,
  DynamicActionSheetComponent,
  DynamicDateSelection,
  DynamicFormCompactLayoutComponent,
  DynamicFormFlexibleLayoutComponent,
  DynamicTableSelectComponent,
  ExpenseRowComponent,
  ExpensesComponent,
  ExpensesWidgetComponent,
  FieldContainerComponent,
  FileInputFieldComponent,
  FiltersContextComponent,
  FormInput,
  FormNavigationComponent,
  FurtherSubscriptionsComponent,
  FurtherSubscriptionsUpdateComponent,
  GenericCondensedRowMobileComponent,
  GenericExpandableRowMobileComponent,
  GenericTableComponent,
  GenericTableContainerComponent,
  GenericTableHeaderComponent,
  GenericTableRowComponent,
  GenericTableRowSkeletonComponent,
  GenericTableToolbarComponent,
  GraphDataViewerComponent,
  HeaderComponent,
  HorizontalSortingEditModeComponent,
  HorizontalSortingSectionComponent,
  HorizontalSortingViewModeComponent,
  IframeComponent,
  IframeViewerComponent,
  IheDocumentsBrowserComponent,
  ImageViewerComponent,
  ImprintComponent,
  InformationCardComponent,
  InformationDocumentRowComponent,
  InformationItemComponent,
  InformationSectionComponent,
  InlineLoaderComponent,
  LabelPrintButtonComponent,
  LaboratoryNotesModalComponent,
  LaboratoryResultsComponent,
  LaboratoryResultsTableComponent,
  LandingpageComponent,
  LanguageSwitchComponent,
  LastActivitiesComponent,
  LinkViewerComponent,
  MedicalCertificateRowComponent,
  MedicalCertificatesComponent,
  MedicalCertificatesWidgetComponent,
  MenuButtonContentComponent,
  ModalComponent,
  MultiChosenComponent,
  NavigationMenuPopoverComponent,
  NextAppointmentsComponent,
  NotificationBoxComponentComponent,
  NumberFieldComponent,
  OrdersComponent,
  OrganizerListItemComponent,
  PatientDataBrowserComponent,
  PatientDataTableComponent,
  PatientDataViewWrapperComponent,
  PatientDetailsHeaderComponent,
  PatientListDataComponent,
  PatientResultRowComponent,
  PatientSearchComponent,
  PatientSummaryBrowserComponent,
  PatientSummaryHeaderComponent,
  PatientSummaryListComponent,
  PatientTableDetailsViewerComponent,
  PdfViewerComponent,
  PinchZoomComponent,
  PreviewFormComponent,
  PrivacyPolicyComponent,
  ProfileCardFieldComponent,
  ProfileCardWrapperComponent,
  ProfileFormComponent,
  ProfileViewComponent,
  PublicBookingModalComponent,
  RequiredActionCardComponent,
  RequiredActionComponent,
  RequiredActionInfoOverviewComponent,
  RequiredActionsWidgetComponent,
  RequiredActionSwitchComponent,
  ResultRow,
  RichtextComponent,
  SearchInputComponent,
  SectionDividerComponent,
  SelectionFieldComponent,
  SelectionFieldPopoverComponent,
  SessionTimeoutComponent,
  ShareTreatmentWithDoctorComponent,
  SubscriptionInformationComponent,
  SwiperContainerComponent,
  SwiperSlideContentComponent,
  TabBarPortalMenuComponent,
  TableSelectComponent,
  TaskRowComponent,
  TaskRowComponent,
  TasksBrowserComponent,
  TasksBrowserComponent,
  TasksBrowserComponentSkeleton,
  TasksListComponent,
  TermsOfServiceBrowserComponent,
  TermsOfServiceButtonComponent,
  TermsOfServiceViewerComponent,
  TestimonialCardComponent,
  TextAreaFieldComponent,
  TextFieldComponent,
  ThemePreviewComponent,
  ThemerComponent,
  TimelineIndicator,
  TitleFieldComponent,
  TreatmentDetailsComponent,
  TreatmentRowComponent,
  TreatmentsBrowserComponent,
  TreatmentsTableComponent,
  TreatmentsWidgetComponent,
  VerticalSortingSectionComponent,
  VideoViewerComponent,
  VideoViewerComponent,
];

const SERVICES = [
  CustomMenuService,
  InactivityService,
  PatientMenuService,
  {
    provide: APP_INITIALIZER,
    useFactory: (inactivityService: InactivityService) => (): Promise<void> =>
      inactivityService.initInactivityObserver(),
    deps: [InactivityService],
    multi: true,
  },
];

const PROVIDERS = [TranslatePipe];

@NgModule({
  imports: [...MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...SERVICES, ...PROVIDERS],
    };
  }
}
