import { SafeResourceUrl } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DynamicTitle } from 'projects/theme/src/lib/components/header/header.model';
import { Invoker, InvokerMethods } from './invoker-body.model';

export class DynamicButton {
  public readonly label: DynamicTitle;
  public readonly invoker: Invoker;
  private readonly fileDetails: FileDetails;
  public readonly icon: IconDefinition;

  constructor(
    label: DynamicTitle,
    invoker?: Invoker,
    fileDetails?: FileDetails,
    icon?: IconDefinition,
  ) {
    this.label = label;
    this.invoker = invoker;
    this.fileDetails = fileDetails;
    this.icon = icon;
  }

  public get hasDeleteActivity(): boolean {
    const methodName: InvokerMethods = this.invoker.invoker.methodName;
    return (
      methodName === InvokerMethods.objectDelete || methodName === InvokerMethods.projectDelete
    );
  }

  public get hasDownloadActivity(): boolean {
    return this.fileDetails instanceof DownloadDetails && !!this.fileDetails?.href;
  }

  public get hasPrintActivity(): boolean {
    return this.fileDetails instanceof PrintDetails && !!this.fileDetails?.safeUrl;
  }

  public get downloadDetails(): DownloadDetails | null {
    return this.hasDownloadActivity ? (this.fileDetails as DownloadDetails) : null;
  }

  public get printDetails(): PrintDetails | null {
    return this.hasPrintActivity ? (this.fileDetails as PrintDetails) : null;
  }
}

export abstract class FileDetails {
  public readonly fileName: string;

  constructor(fileName: string) {
    this.fileName = fileName;
  }
}

export class DownloadDetails extends FileDetails {
  public readonly href: string;

  constructor(fileName: string, href: string) {
    super(fileName);
    this.href = href;
  }
}

export class PrintDetails extends FileDetails {
  public readonly safeUrl: SafeResourceUrl;

  constructor(fileName: string, safeUrl: SafeResourceUrl) {
    super(fileName);
    this.safeUrl = safeUrl;
  }
}

export class DynamicButtonSetConfiguration {
  actionButtons: DynamicButton[];
  triggerButton: ActionSheetTriggerButton;
}

export class ActionSheetTriggerButton {
  label: string;
  dataApiId: string;
  disabled: boolean;
  color: string;
}

export enum ButtonActionType {
  invokerAction = 'invokerAction',
  download = 'download',
  print = 'print',
  cancel = 'cancel',
}
