<button
  [id]="table?.title + '-filter-button'"
  class="filter-button stretch-self tertiary"
  [disabled]="isLoading"
  [attr.aria-label]="'general.filter' | translate"
  (click)="toggleFilterSheet()">
  @if (rowState.isDesktopView) {
    <span class="p3">{{ 'shared.tables.options' | translate }}</span>
  }
  <fa-icon [icon]="icons.columns" [slot]="rowState.isDesktopView ? 'end' : ''"></fa-icon>
</button>
