import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition, IconName, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-notification-box-component',
  template: `
    <lib-auto-layout
      [gap]="[20]"
      [padding]="padding"
      direction="vertical"
      vertical="center"
      horizontal="center"
      class="stretch-self">
      <fa-icon [icon]="icon" size="lg"></fa-icon>
      <h3 class="ion-no-margin">{{ message }}</h3>
      <ng-content select="[notificationBoxButtonCollection]"></ng-content>
    </lib-auto-layout>
  `,
})
export class NotificationBoxComponentComponent {
  @HostBinding('style.width') width = '100%';

  @Input({ required: true }) message: string;
  @Input() icon?: IconDefinition | IconName = faCircleInfo;
  @Input() padding: number[] = [20];
}
