<lib-auto-layout direction="vertical" horizontal="stretch" class="fill-height">
  <lib-generic-table-toolbar
    [data]="data"
    [rowState]="rowState"
    [isCard]="isCard"
    [isLoading]="isLoading"
    (eventMessage)="eventMessage.emit($event)">
    <ng-content toolbarButtonCollection select="[actionButtonCollection]"></ng-content>
  </lib-generic-table-toolbar>

  <div class="table stretch-container stretch-self" [class.shape-card]="isCard">
    <div
      #tableContainer
      class="table stretch-container stretch-flex-bounds no-overflow-x"
      (onDimensionChange)="onTableDimensionChange($event)">
      @if (rowState.isDesktopView) {
        @if (!isDataLoading) {
          <lib-generic-table-header
            class="list-head"
            [table]="data.table"
            [rowState]="rowState"
            (eventMessage)="eventMessage.emit($event)">
          </lib-generic-table-header>
        } @else {
          <lib-generic-table-row-skeleton [rowState]="rowState" [isHeaderItem]="true">
          </lib-generic-table-row-skeleton>
        }
      }

      @if (shouldShowNotificationBox) {
        <lib-notification-box-component
          class="stretch-flex"
          [grow]="1"
          [message]="'shared.table-browser.no-entries-available' | translate">
          <ng-content notificationBoxButtonCollection select="[notificationBoxButtons]">
          </ng-content>
        </lib-notification-box-component>
      } @else {
        @if (shouldShowTableListBrowser) {
          @for (row of data.table.sortedRows; track row.id) {
            <div class="table-row">
              <lib-generic-table-row
                [table]="data.table"
                [row]="row"
                [rowState]="rowState"
                (eventMessage)="eventMessage.emit($event)">
              </lib-generic-table-row>
            </div>
          }
        }

        @if (isLoading) {
          @for (skeleton of skeletonItemList; track $index) {
            <lib-generic-table-row-skeleton
              [rowState]="rowState"
              [rowIcon]="data.skeletonRowSet?.rowIcon"></lib-generic-table-row-skeleton>
          }
        }
      }
    </div>
  </div>
</lib-auto-layout>
