import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { InfiniteScrollCustomEvent, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { LastVisitedPatientsService } from 'projects/core/src/lib/services/last-visited-patients.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
})
export class PatientSearchComponent {
  @Input() set allPatients(patients: Patient[]) {
    this.patients = patients;
    this.initializePatientListData(patients);
  }
  @Input() lastVisitedPatients: Patient[] = [];
  @Input() showPatientCreateButton: boolean = false;
  @Input() isLoading: boolean = true;

  patients: Patient[];
  filteredPatients: Patient[];
  displayedPatientsCount: number;

  readonly searchParams: SearchParams = new SearchParams();
  readonly patientsLoadIncrement: number = 30;

  constructor(
    private itemSearcherService: ItemSearcherService,
    private patientService: PatientService,
    private router: Router,
    private popupService: PopupService,
    private visitedPatientService: LastVisitedPatientsService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private alertService: AlertService,
  ) {
    this.searchParams.fields = [
      'pid',
      'firstName',
      'lastName',
      'birthDate',
      'sex',
      'currentVisit',
      'street',
      'zipCode',
      'domicile',
      'phoneNumber',
      'email',
    ];
  }

  get maxSkeletonAmountAsArray(): number[] {
    return SkeletonService.getRowAmountAsArray(262, 68);
  }

  async createPatient(): Promise<void> {
    const patientId: string = await this.patientService.createPatient();
    if (patientId) {
      await this.navigateToCreatedPatient(patientId);
    }
  }

  async openPatient(chosenPatient: Patient): Promise<void> {
    await this.showTranslatedPatientLoadingMessage();
    await this.saveVisitedPatientIdAndNavigate(chosenPatient);
  }

  getPatientResults(): void {
    if (!this.patients.length) {
      return;
    }
    if (this.searchParams.criterions) {
      this.filteredPatients = this.itemSearcherService.search(this.patients, this.searchParams);
    } else {
      this.filteredPatients = this.patients.slice(0, this.displayedPatientsCount);
    }
    this.isLoading = false;
  }

  showLoading(): void {
    this.isLoading = true;
  }

  async displayMorePatients(event: InfiniteScrollCustomEvent): Promise<void> {
    this.displayedPatientsCount += this.patientsLoadIncrement;
    this.filteredPatients = this.patients.slice(0, this.displayedPatientsCount);
    await event.target.complete();
  }

  private initializePatientListData(patients: Patient[]): void {
    this.resetPatientListData();
    if (patients.length) {
      this.filteredPatients = this.patients.slice(0, this.displayedPatientsCount);
    }
  }

  private async showTranslatedPatientLoadingMessage(): Promise<void> {
    const loadingMessage: string = await firstValueFrom(
      this.translateService.get('shared.patients.opening-patient'),
    );
    await this.loadingService.load(loadingMessage);
  }

  private async navigateToCreatedPatient(patientsId: string): Promise<void> {
    try {
      await this.showTranslatedPatientLoadingMessage();
      const patient: Patient = await this.patientService.retrieveRecentlyCreatedPatient(patientsId);
      await this.saveVisitedPatientIdAndNavigate(patient);
    } catch (error) {
      await this.loadingService.stop();
      await this.alertService.presentAlert(AlertMessagesData.httpRequestError);
      await this.modalController.dismiss(error, 'error');
    }
  }

  private async saveVisitedPatientIdAndNavigate(patient: Patient): Promise<void> {
    await firstValueFrom(
      this.visitedPatientService.updateLastVisitedPatientIdList(patient.patientID),
    );
    await this.navigateToPatient(patient);
    await this.loadingService.stop();
  }

  private resetPatientListData(): void {
    this.displayedPatientsCount = this.patientsLoadIncrement;
    this.searchParams.criterions = null;
  }

  private async navigateToPatient(patient: Patient): Promise<void> {
    await this.router.navigate([this.router.url, patient.patientID.toString()], {
      state: { patient },
    });
  }
}
