<div class="grid-container">
  <lib-profile-card-wrapper
    class="contact-information"
    *ngIf="isSectionVisible(ProfileSection.contactInfo)"
    [isLoading]="isLoading"
    title="{{ 'shared.profile.profile' | translate }}"
    [fieldKeys]="contactInfoFieldKeys"
    [hasEditableFields]="hasFormSectionEditableFields(contactInfoFieldKeys)"
    (profileDataUpdate)="updateProfileData()">
    <lib-contact-information
      *ngIf="!isLoading"
      [fields]="getFilteredViewFields(contactInfoFieldKeys)">
    </lib-contact-information>
  </lib-profile-card-wrapper>

  <lib-profile-card-wrapper
    class="account-information"
    *ngIf="isSectionVisible(ProfileSection.accountInfo)"
    [isLoading]="isLoading"
    title="{{ 'shared.profile.account' | translate }}"
    [fieldKeys]="accountInfoFieldKeys"
    [hasEditableFields]="hasFormSectionEditableFields(accountInfoFieldKeys)"
    (profileDataUpdate)="updateProfileData()">
    <lib-account-information
      *ngIf="!isLoading"
      [fields]="getFilteredViewFields(accountInfoFieldKeys)"
      [userDeactivationForm]="userDeactivationForm"
      [changePasswordLink]="changePasswordLink"
      [authCockpitLink]="authCockpitLink"
      [openAuthCockpitBlank]="openAuthCockpitBlank">
    </lib-account-information>
  </lib-profile-card-wrapper>

  <div class="flex-column-20">
    <lib-profile-card-wrapper
      class="subscription-information"
      *ngIf="isSectionVisible(ProfileSection.subscriptionInfo)"
      [isLoading]="isLoading"
      [title]="subscriptionInfoFormGroup?.name"
      [groupIndex]="subscriptionGroupIndex"
      [hasEditableFields]="hasFormSectionEditableFields(undefined, subscriptionGroupIndex)"
      (profileDataUpdate)="updateProfileData()">
      <lib-subscription-information
        *ngIf="!isLoading"
        [fields]="getFilteredViewFields(undefined, subscriptionGroupIndex)">
      </lib-subscription-information>
    </lib-profile-card-wrapper>

    <lib-profile-card-wrapper
      class="further-subscription-information"
      *ngIf="profileService?.profileSubscriptionElements?.length"
      [isLoading]="isLoading"
      [title]="'shared.profile.further-subscriptions' | translate"
      [groupIndex]="furtherSubscriptionsGroupIndex"
      [hasEditableFields]="true"
      (profileDataUpdate)="updateProfileData()">
      <lib-further-subscriptions
        *ngIf="!isLoading"
        [fields]="profileService.profileSubscriptionElements">
      </lib-further-subscriptions>
    </lib-profile-card-wrapper>
  </div>
</div>
